import React, { Fragment, useEffect, useRef } from 'react'

const ContactForm = () => {

  const refCognito = useRef(null)

  useEffect(() => {
    if (!refCognito.current) return
    const script = document.createElement('script')
    script.src = './script/cognitoform.js'
    script.setAttribute('data-key', '7KUKdm_1LUO_xcQcjTlRAw')
    script.setAttribute('data-form', '2')
    refCognito.current.appendChild(script)
  }, [])
    return (
        <Fragment>
              <div className="col-12  mb-7">
                  <div ref={refCognito} />
                </div>
        </Fragment>
    );
};

export default ContactForm;
