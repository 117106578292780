import React, { useEffect } from 'react'
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import  {CaseStudy} from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactContainer from '../containers/contact'
import TeamPage from './team'
import AboutContainer from "../containers/about";
import ScrollHandler from "../components/menu/ScrollHandler";

const HomePage = () => {

  useEffect(() => {

    const handle = () => {
      const elements = document.getElementsByTagName("section");

      for (let i = 0; i < elements.length; i++) {
        const y = elements[i].getBoundingClientRect().bottom - 300;
          if(y >= 0) {
          const id = elements[i].id;

          const links = document.getElementsByClassName("link-header");

          for (let j = 0; j < links.length; j++) {
            links[j].classList.remove("active-link")

            if(links[j].hash === id) {
              links[j].classList.add("active-link")
            }
          }

          break;
        }
      }
    }

    window.addEventListener("scroll", handle)

    return () => {
      window.removeEventListener("scroll", handle)
    }
  }, [])

    return (
        <Layout>
            <ScrollHandler />
            <SEO title="Ruby On Ring" />
            <div className="wrapper">
                <Header />
                <IntroContainer/>
                <ServiceListContainer classOption="mt-10 mt-lg-0" />
                <CaseStudy/>
                <TeamPage />
                <AboutContainer />
                <ContactContainer/>
                <Footer />
                <ScrollToTop />
            </div>
        </Layout>
    );
};

export default HomePage;
