import { NavLink } from "react-router-dom";

const MainMenu = () => {
    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex">
                <li>
                    <NavLink
                        className={`link-header`}
                        to="/#home"
                    >
                        ホーム
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`link-header`}
                        to={"/#service"}
                    >
                        サービス
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`link-header`}
                        to={"/#casestudy"}
                    >
                        開発実績
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`link-header`}
                        to={"/#member"}
                    >
                        メンバー
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`link-header`}
                        to={"/#about"}
                    >
                        会社情報
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={`link-header`}
                        to={"/#contact"}
                    >
                        お問い合わせ
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
