import { Fragment, useEffect, useState } from "react";
import Logo from "../../components/logo";
import MainMenu from "../../components/menu/main-menu";
import MobileMenu from "../../components/menu/mobile-menu";

const Header = () => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow((prev) => !prev);
    };
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    useEffect(() => {
        const header = document.querySelector(".sticky-header");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <Fragment>
            <header
                className={`header-section sticky-header ${
                    scroll > headerTop ? "is-sticky" : ""
                }`}
            >
                <div className="container">
                    <div className="row align-items-center container-header ">
                        <div >
                            <div className="header-logo" style={{width:'110px'}}>
                                <Logo
                                    classOption="d-block"
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo-ruby-on-ring.png`}
                                />
                            </div>
                        </div>

                        <div className="text-end">
                            <MainMenu />
                            <button
                                className="toggle mr-10"
                                onClick={onCanvasHandler}
                            >
                                <span className="icon-top"/>
                                <span className="icon-middle"/>
                                <span className="icon-bottom"/>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow}  onClose={onCanvasHandler} />
        </Fragment>
    );
};

export default Header;
