import React from 'react'
import Slider from 'react-slick'

const CASE_STUDY = [
    {
        id: '1',
        name: '相続税申告支援システム',
        content:
            '直接税務署に行かなくオンラインだけで相続税を申告できるアプリである。ユーザは情報を記入した後、自動的に税金が計算される。オンライン申告書を出し、CSVファイルを出力できる。',
        tags: ['フィンテック', 'ウェブ'],
        url: 'images/blog/tax.jpg',
        time: '6か月',
        mm: '20人月',
    },
    {
        id: '2',
        name: '株価チャート',
        content:
            '日本、アメリカ、中国市場の株価チャート機能に関するプロジェクトである。株式を検索し、チャートとしてを表示する。',
        tags: ['フィンテック', 'ウェブ'],
        url: 'images/blog/stock_chart.jpg',
        time: '13か月',
        mm: '52人月',
    },
    {
        id: '3',
        name: '資産に対するアドバイスのシステム',
        content:
            'ユーザが情報を登録した上で、資産運用についてのアドバイス、財政の計画を出す。',
        tags: ['フィンテック', 'ウェブ'],
        url: 'images/blog/financial.jpg',
        time: '2か月',
        mm: '6人月',
    },
    {
        id: '4',
        name: 'ライフプランシミュレーションのシステム',
        content:
            '様々な統計データを元に、「85歳時点での生涯収支」をシミュレーションができる。シミュレーション結果では、将来の不足資金・余剰資金、月々の貯蓄方法までを知ることができる。',
        tags: ['フィンテック', 'ウェブ'],
        url: 'images/blog/budge_planning.jpg',
        time: '2か月',
        mm: '6人月',
    },
    {
        id: '5',
        name: '家計診断',
        content:
            '年齢、性別、子供の数などの個人情報を入力し、5つの質問のアンケートに回答した上で、財務のシミュレーションを出す。バナー広告を通じてユーザーに解決策を推奨する。',
        tags: ['フィンテック', 'ウェブ'],
        url: 'images/blog/family_budget.jpg',
        time: '3か月',
        mm: '12人月',
    },
    {
        id: '6',
        name: '所得税の確定申告のシステム',
        content:
            '確定申告向けに必要な個人情報を入力した上で、国税庁の申請書のフォーマットに移動する。',
        tags: ['フィンテック', 'ウェブ'],
        url: 'images/blog/tax2.jpg',
        time: '12か月',
        mm: '17人月',
    },
    {
        id: '7',
        name: '従業員の給料情報の表示システム',
        content:
            '従業員の勤怠、給与などのデータを形式に入力し、PDF に出力できる。入力されたすべての情報は表示/非表示の選択のみ可能である。',
        tags: ['ERPシステム', 'ウェブ'],
        url: 'images/blog/accountant.jpg',
        time: '5か月',
        mm: '15人月',
    },
    {
        id: '8',
        name: '住宅ローン',
        content:
            'ユーザーがスマホで入力した銀行融資に必要な情報やアップロードした画像を提供する。 銀行で手動的な入力の手間がかからない。',
        tags: ['フィンテック', 'ウェブ', 'アプリ'],
        url: 'images/blog/home.jpg',
        time: '14か月',
        mm: '80人月',
    },
    {
        id: '9',
        name: 'Herokuサービスへのユーザー登録システム',
        content:
            'ユーザーの個人情報と口座情報の登録・編集機能を持つWebAppを開発する。ユーザーからの情報変更要求があれば、直接に変更せずに、別システムに変更要求を送って、承認・却下の結果によって変更内容を反映する。',
        tags: ['ERPシステム', 'ウェブ'],
        url: 'images/blog/contact.jpg',
        time: '8か月',
        mm: '30人月',
    },
    {
        id: '10',
        name: '出荷管理システム',
        content:
            '商品情報を把握し、商品が不足し発注の遅れるリスク回避に、発注アラートが出る。',
        tags: ['ERPシステム','ウェブ'],
        url: 'images/blog/sale.jpg',
        time: '5か月',
        mm: '10人月',
    },
    {
        id: '11',
        name: '不動産賃貸取引に特化した電子契約システム',
        content:
            '入居希望者様が申込時に入力した情報を、契約時にそのまま署名者情報として利用できる。煩わしいデータ入力がなくなることで業務が効率化する。',
        tags: ['ERPシステム', 'ウェブ'],
        url: 'images/blog/key.jpg',
        time: '8か月',
        mm: '16人月',
    },
    {
        id: '12',
        name: '保険計画のシステム',
        content:
            '年齢、性別、子供の数、子供の年齢、個人の収入などの個人情報を入力してシミュレーションを出力する。ユーザーが長期にわたる支出を把握したり、保険、教育、住宅ローンの見直しが必要な情報を視覚化する。',
        tags: ['フィンテック', 'ウェブ'],
        url: 'images/blog/insurance.jpg',
        time: '6か月',
        mm: '14人月',
    },
]

/**
 * @returns Component case study
 */
export const CaseStudy = () => {
    /**
     * Click prev arrow slide
     */
    const GalleryPrevArrow = ({ ...props }) => {
        const { onClick } = props

        return (
            <div className="custom-prevArrow" onClick={onClick} >
                <svg
                    className={'svg_icon_left'}
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    fill={'red'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
                        fill="red"
                    />
                </svg>
            </div>
        )
    }

    /**
     * Click next arrow slide
     */
    const GalleryNextArrow = ({ ...props }) => {
        const { onClick } = props

        return (
            <div className="custom-nextArrow" onClick={onClick}>
                <svg
                    className={'svg_icon_right'}
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996"
                        fill="red"
                    />
                </svg>
            </div>
        )
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <GalleryNextArrow />,
        prevArrow: <GalleryPrevArrow />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 986,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    }
    return (
        <section id={'#casestudy'} style={{marginTop:'150px'}}>
            <div className="blog-section section-pb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 offset-lg-2">
                            <div className="title_default_light title_border text-center">
                                <h1
                                    className="animation"
                                    data-animation="fadeInUp"
                                    data-animation-delay="0.2s"
                                >
                                    開発実績
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Slider {...settings}>
                                {CASE_STUDY.map((item) => {
                                    return (
                                        <div className={'wrapper'} key={item.id}>
                                            <div
                                                className="blog_item animation"
                                                data-animation="fadeInUp"
                                                data-animation-delay="0.2s"
                                            >
                                                <div className="blog_img ">
                                                    <a>
                                                        <img alt="placeholder1" src={item.url} />
                                                    </a>
                                                </div>
                                                <div className="blog_content">
                                                    <div className="blog_text">
                                                        <h6 className="blog_title">
                                                            <a>{item.name}</a>
                                                        </h6>
                                                        <p>{item.content}</p>
                                                        <div className={'blog_tag'}>
                                                            {item?.tags?.map((tag, index) => (
                                                                <mark key={index} className="gradient_box ">
                                                                    {tag}
                                                                </mark>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <ul className="list_study list_none blog_meta">
                                                        <li>
                                                            <a>
                                                                <img alt="" src={'images/blog/calendar.png'} /> {item.time}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a>
                                                                <img alt="" src={'images/blog/icon.png'} />
                                                                {item.mm}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
