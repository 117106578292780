import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutContainer from "../containers/about";
import PageBanner from "../containers/global/page-banner";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import '../assets/css/style.css'

const AboutPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Ruby On Ring -Company profile" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="会社情報"
                        image="./images/banner/1.png"
                    />
                    <AboutContainer />
                    <ServiceListContainer />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AboutPage;
