import PropTyps from "prop-types";

const TeamCard = ({ data }) => {
    return (
        <div className="team-card height_div">
            <div className="thumb">
                <img className="img_member" src={process.env.PUBLIC_URL + data.thumb} alt="img" />
            </div>
            <div className="content">
                <h3 className="title">{data.name}</h3>
                <h2 className="title">{data.designation}</h2>
                <div className="span-team"><span>{data.content}</span></div>
            </div>
        </div>
    );
};

TeamCard.propTypes = {
    data: PropTyps.object,
};

export default TeamCard;
