import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <img
                    className="footer-shape img_color"
                    src={process.env.PUBLIC_URL + "/images/footer/footer.png"}
                    alt="shape"
                />
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-8"
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo-ruby-on-ring.png`}
                                />
                                <p>
                                    我が社はベトナム・ハノイに拠点を置いており、少人数のエキスパートが効率的にお客様の課題を解決致します。Ruby On Ring Joint Stock Companyはすべての企業へRUBY言語におけるウェブ開発を高品質で提供させていただきます。
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-7 offset-xl-1">
                            <div className="footer-widget margin-left">
                                <h4 className="title">Quick Link</h4>
                                <ul className="footer-menu">
                                    <li>
                                        <Link
                                          className="footer-link"
                                          to={"/#home"}
                                        >
                                            ホーム
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                          className="footer-link"
                                          to={"/#service"}
                                        >
                                            サービス
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                          className="footer-link"
                                          to={"/#casestudy"}
                                        >
                                            開発実績
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={"/#member"}
                                        >
                                            メンバー
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={"/#about"}
                                        >
                                            会社情報
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                          className="footer-link"
                                          to={"/#contact"}
                                        >
                                            お問い合わせ
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4 className="title margin-top">ACCESS</h4>
                                <iframe
                                  className="address-map"
                                  allowFullScreen
                                  height="300px"
                                  loading="lazy"
                                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.9374362403164!2d105.76576981379881!3d21.03518919125538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313454b93c73ca9d%3A0x210784911132ebe!2zMjQgUGjhu5EgTmd1eeG7hW4gQ8ahIFRo4bqhY2gsIE3hu7kgxJDDrG5oLCBU4burIExpw6ptLCBIw6AgTuG7mWksIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1637831847073!5m2!1sen!2s"
                                  style={{ border: 0 }}
                                  width="100%"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <p>
                                    Copyright © 2023 Ruby on Ring JSC, All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
