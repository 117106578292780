const AboutContainer = () => {

    return (
        <section id={'#about'}>
            <div className={'container-about container'}>
                <div className={'text-about'}>会社概要</div>
                <div className={"container-current-about"}>
                    <div className={" title-about"}>会社名</div>
                    <div className={"title-agency"}/>
                    <div className={" content-about"}>株式会社 Ruby on Ring</div>
                </div>
                <div  className={"container-current-about"}>
                    <div className={" title-about"}>設立</div>
                    <div className={"title-agency"}/>
                    <div  className={" content-about"}>2021年1月</div>
                </div>
                <div  className={"container-current-about"}>
                    <div className={" title-about"}>従業員数</div>
                    <div className={"title-agency"}/>
                    <div  className={" content-about"}>30名（2023年5月1日現在）</div>
                </div>
                <div  className={"container-current-about"}>
                    <div className={" title-about"}>代表取締役</div>
                    <div className={"title-agency"}/>
                    <div  className={" content-about"}>Luong Xuan Hai</div>
                </div>
                <div  className={"container-current-about"}>
                    <div className={" title-about"}>事業内容</div>
                    <div className={"title-agency"}/>
                    <div  className={" content-about"}>Ruby, Ruby on Rails 開発</div>
                </div>
                <div  className={"container-current-about"}>
                    <div className={" title-about"}>関連会社</div>
                    <div className={"title-agency"}/>
                    <div  className={" content-about"} style={{display:'flex', flexDirection:'column'}}>
                        <a className={'link-company'} href={'https://reactplus.com/home'}>株式会社 React Plus（親会社）</a>
                        <a className={'link-company'} href={'https://maingo.jp/'}>株式会社 Main.Go</a>
                        <a className={'link-company'} href={'https://whitedot.jp'}>株式会社 WhiteDot</a>
                    </div>
                </div>
                <div  className={"container-current-about"}>
                    <div className={" title-about"}>所在地</div>
                    <div className={"title-agency"}>
                        (本社)
                    </div>
                    <div style={{display:'flex', flexDirection:'column'}} className={" content-about"}>
                        7th Floor, Golden Field Building,<br/>24 Nguyen Co Thach Street, Nam Tu Liem District, Hanoi, Vietnam
                    </div>
                </div>
                <div  className={"container-current-about"}>
                    <div className={" title-about"}/>
                    <div className={"title-agency"}>
                        (東京本社)
                    </div>
                    <div style={{display:'flex', flexDirection:'column'}} className={" content-about"}>
                        東京都港区芝浦4丁目12番38号<br/>CANAL GATE SHIBAURA（キャナル ゲート芝浦）ビル
                    </div>
                </div>
                <div  className={"container-current-about"}>
                    <div className={" title-about"}>認証取得</div>
                    <div className={"title-agency"}/>
                    <div  className={" content-about"} >
                        <img src={"images/about/ido2015.png"} style={{height:'150px', marginRight:'10%'}}/>
                        <img src={"images/about/iso2013.png"} style={{height:'150px'}}/>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default AboutContainer;
