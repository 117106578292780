import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import Parallax from "parallax-js";

const Intro = ({ data }) => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="hero-slider">
            <div className="container">
                <div className="row align-items-center mt-60" style={{display:'flex' , textAlign:'center'}}>
                    <div
                        className="scene mt-10 mt-lg-0 mb-30 "
                        id="scene"
                        ref={sceneEl}
                    >
                        <div className={'mt-home'} style={{paddingTop: '50px'}}>
                            <img
                                style={{maxWidth:'350px', minHeight:'350px'}}
                                src={`${process.env.PUBLIC_URL}/${data.image}`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="hero-slide-content" >
                        <h2
                        className="title animated text-ruby-on-ring"
                            dangerouslySetInnerHTML={{ __html: data.title }}
                        />
                        <h4
                            className="title animated text-content-home"
                            dangerouslySetInnerHTML={{ __html: data.content }}
                        />
                       <div className={"container-button-home"}>
                           <Button
                               classOption="btn animated delay1 me-4 mb-3 mb-sm-0 btn-getstart"
                               text="会社概要"
                               path="/#about"
                           />
                           <Button
                               classOption="btn animated delay2 me-4 mb-3 mb-sm-0 btn-getstart"
                               text="お問い合わせ"
                               path="/#contact"
                           />
                       </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};


export default Intro;
