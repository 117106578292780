import React from "react";
import TeampPageContainer from "../containers/team-page-container";
import Layout from "../layouts/index";
import SectionTitle from '../components/section-title'
import SEO from '../components/seo'
import Header from "../layouts/header";

const TeamPage = () => {
    return (
        <section id={'#member'}>
            <Header />
            <React.Fragment >
                <Layout>
                    <SEO title="Ruby On Ring" />
                    <div style={{marginTop:'150px'}}>
                        <div className="col-xl-6 col-lg-8 mx-auto">
                            <SectionTitle
                                classOption="title-section mb-10 pb-10 text-center container"
                                title="OUR KEY MEMBER"
                                excerptClassOption="null"
                                excerpt=" Ruby on Ringは経験豊富な専門家で構成されています。唯一無二のRubyとRuby on Railsに特化したベトナムアウトソーシング会社を目指しています。"
                            />
                        </div>
                        <TeampPageContainer />
                    </div>
                </Layout>
            </React.Fragment>
        </section>
    );
};

export default TeamPage;
